import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./AdminPanelSystem.css"; // Import your custom CSS

interface System {
  id: number;
  system_name: string;
  status: string;
  guid: string; // Add the guid to the System interface
  onDelete: (id: number) => void;
  onUnsubscribe: (system: System) => void; // Use guid for unsubscribing
}

interface AdminPanelSystemsProps {
  systems: System[];
}

const AdminPanelSystems: React.FC<AdminPanelSystemsProps> = ({ systems }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedSystem, setSelectedSystem] = useState<System | null>(null);

  const handleShowModal = (system: System) => {
    setSelectedSystem(system);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedSystem(null);
  };

  return (
    <div className="admin-panel-systems">
      {systems.map((system) => {
        const isUnsubscribed = system.status === "unsubscribed";

        return (
          <Card
            key={system.id}
            className={`system-card ${
              isUnsubscribed ? "unsubscribed-system" : ""
            }`}
            onClick={() => handleShowModal(system)}
            style={{
              backgroundColor: isUnsubscribed ? "#f8d7da" : "#fff", // Red background for unsubscribed systems
              opacity: isUnsubscribed ? 0.5 : 1, // Make unsubscribed systems semi-transparent
            }}
          >
            <Card.Body className="system-card-body">
              <div>
                <Card.Title className="system-card-title">
                  {system.system_name}
                </Card.Title>
                {isUnsubscribed && (
                  <Card.Subtitle className="mb-2 text-muted">
                    This system has been disabled.
                  </Card.Subtitle>
                )}
              </div>

              {/* Only show the Unsubscribe button if the system is not unsubscribed */}
              {!isUnsubscribed && (
                <Button
                  variant="warning"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent card click event from firing
                    system.onUnsubscribe(system);
                  }}
                  style={{ marginRight: "10px", color: "red" }}
                >
                  Unsubscribe
                </Button>
              )}
            </Card.Body>
          </Card>
        );
      })}

      {/* Modal for showing license key */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>License Key</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedSystem ? (
            <>
              <p>
                <strong>System Name:</strong> {selectedSystem.system_name}
              </p>
              <p>
                <strong>License Key:</strong>{" "}
                {selectedSystem.guid || "No license key available"}
              </p>
            </>
          ) : (
            <p>No system selected.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AdminPanelSystems;
