export async function loginHandler(email: string, password: string) {
  try {
    const response = await fetch("/api/auth", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ email, password }),
    });

    const data = await response.json();
    console.log(data);
    return data;
    // Handle data or update state as needed
  } catch (error) {
    console.error("Error logging in:", error);
    return error;
  }
}
