import getSubdomain from "../utils/utiltyFunctions";
export async function checkUserInOrg(userId: string) {
  const subdomain = getSubdomain();
  try {
    const response = await fetch("/api/checkUserInOrg", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ subdomain, userId }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error finding org:", error);
    return null;
  }
}
