// src/services/api/getOrgSystems.ts
import getSubdomain from "../utils/utiltyFunctions"; // Assuming you have a function to get the subdomain

export async function getOrgSystems() {
  const subdomain = getSubdomain();
  try {
    const response = await fetch("/api/v1/getOrgSystems", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ subdomain }),
    });

    if (!response.ok) {
      throw new Error("Failed to fetch systems.");
    }

    const data = await response.json();
    console.log(data.systems);
    return data.systems; // Assuming the backend returns an array of systems in `data.systems`
  } catch (error) {
    console.error("Error fetching systems:", error);
    throw error;
  }
}
