function getSubdomain() {
  const windowURL = window.location.hostname;
  let subdomain = "";
  if (windowURL.includes("epicarealert.com")) {
    subdomain = windowURL.split(".")[0];
  } else {
    subdomain = "unds";
  }
  return subdomain;
}
export default getSubdomain;
