// src/Pages/PaymentForm.tsx
import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import getSubdomain from "../services/utils/utiltyFunctions";
import Spinner from "react-bootstrap/Spinner";
import "./AddPaymentForm.css"; // Custom CSS for further styling

interface AddPaymentFormProps {
  show: boolean;
  handleClose: () => void;
  handlePaymentSuccess: (data: any) => void;
  handlePaymentFailure: (error: any) => void;
}

const AddPaymentForm: React.FC<AddPaymentFormProps> = ({
  show,
  handleClose,
  handlePaymentSuccess,
  handlePaymentFailure,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState<string>("");
  const [cardholderName, setCardholderName] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [zip, setZip] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const cardElement = elements.getElement(CardElement);
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement!,
        billing_details: {
          email,
          name: cardholderName,
          address: {
            line1: address,
            city,
            state,
            postal_code: zip,
          },
        },
      });

      if (error) {
        setError(
          error.message || "An error occurred while processing your payment"
        );
        setLoading(false);
        handlePaymentFailure(error);
        return;
      }
      const subdomain = getSubdomain();
      // Send payment method to the backend
      const response = await fetch("/api/v1/addPaymentMethod", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          paymentMethodId: paymentMethod!.id,
          subdomain,
        }),
      });

      if (!response.ok) {
        const errData = await response.json();
        setError(
          errData.message || "An error occurred while processing your payment"
        );
        setLoading(false);
        handlePaymentFailure(errData);
        return;
      }

      const data = await response.json();
      setLoading(false);
      handlePaymentSuccess(data);
    } catch (err: any) {
      setError(
        err.message || "An error occurred while processing your payment"
      );
      setLoading(false);
      handlePaymentFailure(err);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Payment Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <div className="form-section">
            <h5>Contact Information</h5>
            <Form.Group controlId="formEmail">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                isInvalid={!!error}
              />
            </Form.Group>
          </div>

          <hr />

          <div className="form-section">
            <h5>Billing Information</h5>
            <Form.Group controlId="formCardholderName" className="mt-3">
              <Form.Label>Cardholder Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter cardholder's name"
                value={cardholderName}
                onChange={(e) => setCardholderName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formAddress" className="mt-3">
              <Form.Label>Billing Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter billing address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formCity" className="mt-3">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formState" className="mt-3">
              <Form.Label>State</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter state"
                value={state}
                onChange={(e) => setState(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formZip" className="mt-3">
              <Form.Label>ZIP Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter ZIP code"
                value={zip}
                onChange={(e) => setZip(e.target.value)}
                required
              />
            </Form.Group>
          </div>

          <hr />

          <div className="form-section">
            <h5>Card Details</h5>
            <Form.Group controlId="formCard" className="mt-3">
              <CardElement />
            </Form.Group>
          </div>

          {error && <div className="text-danger mt-3">{error}</div>}

          <Button
            variant="primary"
            type="submit"
            disabled={loading || !stripe}
            className="mt-4 w-100"
          >
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />{" "}
                Processing...
              </>
            ) : (
              "Submit Payment"
            )}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddPaymentForm;
