import getSubdomain from "../utils/utiltyFunctions";

export async function getOrgCustomerId() {
  const subdomain = getSubdomain();
  try {
    // Call backend to handle payment and system creation
    const response = await fetch("/api/v1/getOrgCustomerId", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        subdomain,
      }),
    });

    const result = await response.json();
    console.log(result);
    if (response.ok) {
      return result;
    } else {
      // throw new Error(result.message);
    }
  } catch (error) {
    console.error("Error fetching org customerId:", error, subdomain);
    return error;
  }
}
