import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "./Contexts/AuthContext";
import Spinner from "react-bootstrap/Spinner";
import BottomNavBar from "./Navbar/BottomNavbar"; // Make sure to import the BottomNavBar
import React from "react";

const ProtectedRoute = () => {
  const { isAuthenticated, isLoading, user } = useAuth();
  const location = useLocation();
  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="grow">
          <div className="content" />
        </Spinner>
      </div>
    );
  }

  return isAuthenticated ? (
    <>
      <Outlet /> {/* This will render the nested route components */}
      <BottomNavBar />{" "}
      {/* BottomNavBar will be displayed for all protected routes */}
    </>
  ) : (
    <Navigate to="/" replace />
  );
};

export default ProtectedRoute;
