// CancelSubscriptionModal.tsx
import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

interface CancelSubscriptionModalProps {
  show: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  systemName: string;
}

const CancelSubscriptionModal: React.FC<CancelSubscriptionModalProps> = ({
  show,
  handleClose,
  handleConfirm,
  systemName,
}) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Cancel Subscription for {systemName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to cancel the subscription for{" "}
          <strong>{systemName}</strong>? The system will stop functioning after
          the cancellation period.
        </p>
        <p>
          This action is permanent and cannot be undone. The system will no
          longer be accessible after the subscription expires.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="danger" onClick={handleConfirm}>
          Cancel Subscription
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CancelSubscriptionModal;
