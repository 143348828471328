import getSubdomain from "../utils/utiltyFunctions";

export async function orgChecker() {
  const subdomain = getSubdomain();
  try {
    const response = await fetch("/api/getOrganization", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ subdomain }),
    });
    console.log(response);
    const data = await response.json();
    if (data?.message && data.message === "FOUND_ORG") return data;
    else return null;
  } catch (error) {
    console.error("Error finding org:", error);
    return error;
  }
}
