import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import UnclaimedSystem from "../components/System";
import ClaimedSystem from "../components/ClaimedSystem";
import { Menu } from "lucide-react";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useAuth } from "../components/Contexts/AuthContext";
import { useOnlineUsers } from "../components/Contexts/OnlineUsersContext"; // Import the context hook
import { useSocket } from "../components/Contexts/SocketContext"; // Import the socket context
import { useSystems } from "../components/Contexts/SystemsContext"; // Import the SystemsContext
import "./dashboard.css";

interface DashboardProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  unclaimSystem: (id: number) => void;
  selectedUser?: string | null;
  selectedUserName?: string | null;
  moveSystemToDashboard: (id: number) => void;
}

interface System {
  id: number;
  system_name: string;
  patient_name: string;
  status: string;
  battery_lvl: number;
  charge_status: boolean;
  screenshot?: any;
  claimedby: string | null;
}

Modal.setAppElement("#root");

const Dashboard: React.FC<DashboardProps> = ({
  isOpen,
  setIsOpen,
  unclaimSystem,
  selectedUser,
  selectedUserName,
  moveSystemToDashboard,
}) => {
  const { systems, setSystems } = useSystems();
  const [unclaimedSystems, setUnclaimedSystems] = useState<System[]>([]);
  const [claimedSystems, setClaimedSystems] = useState<System[]>([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalSystems, setModalSystems] = useState<any[]>([]); // Store multiple systems with screenshots
  const [fullscreenImage, setFullscreenImage] = useState<string | null>(null);
  const [notes, setNotes] = useState<{ [key: number]: string }>({}); // Store notes per system ID
  const { onlineUsers, setOnlineUsers } = useOnlineUsers(); // Get onlineUsers and setOnlineUsers from context
  const auth = useAuth(); // Assume this provides the logged-in user's ID and organizationId
  const { socket } = useSocket(); // Get the socket from context

  async function runCheck(systemId: number) {
    console.log(systemId);
    if (socket) {
      socket.emit("RUN_CHECK", {
        systemId: systemId,
        organizationId: auth.user?.organizationid,
        userId: auth.user?.sub,
      });

      // Update the system's status to "RUN_CHECK"
      setSystems((prevSystems) =>
        prevSystems.map((s) =>
          s.id === systemId ? { ...s, status: "RUN_CHECK" } : s
        )
      );
    }
  }

  useEffect(() => {
    const systemsWithScreenshots = systems.filter(
      (sys) =>
        sys.screenshot &&
        sys.claimedby === auth.user?.sub &&
        sys.status === "awaiting_approval"
    );

    setModalSystems(systemsWithScreenshots);
    setModalIsOpen(systemsWithScreenshots.length > 0);
  }, [systems, auth.user]);

  useEffect(() => {
    if (auth.isAuthenticated && socket) {
      socket.emit("getOnlineUsers");

      socket.on("onlineUsers", (users: any[]) => {
        setOnlineUsers(users);
      });

      socket.on("disconnect", () => {
        setSystems([]);
      });

      return () => {
        socket.off("onlineUsers");
      };
    }
  }, [auth.isAuthenticated, socket, setOnlineUsers]);
  // Update unclaimed and claimed systems whenever 'systems' or 'selectedUser' changes
  useEffect(() => {
    setUnclaimedSystems(systems.filter((sys) => !sys.claimedby));
    setClaimedSystems(
      systems.filter(
        (sys) => sys.claimedby === (selectedUser || auth.user?.sub)
      )
    );
  }, [systems, selectedUser, auth.user?.sub]);
  const handleImageClick = (imageUrl: string) => {
    setFullscreenImage(imageUrl);
  };

  const handleNoteChange = (systemId: number, value: string) => {
    setNotes((prevNotes) => ({
      ...prevNotes,
      [systemId]: value,
    }));
  };
  const handleApprove = (system: System) => {
    if (socket) {
      socket.emit("APPROVE_CHECK", {
        id: system.id,
        note: notes[system.id],
      });

      // Remove the system from modalSystems
      setModalSystems((prev) => prev.filter((s) => s.id !== system.id));

      // Close the modal if no systems are left
      if (modalSystems.length === 1) setModalIsOpen(false);
    }
  };
  const handleDeny = (system: System) => {
    if (socket) socket.emit("DENY_CHECK", system);
    setModalSystems((prev) => prev.filter((s) => s.id !== system.id));
    if (modalSystems.length === 1) setModalIsOpen(false);
  };

  const closeFullscreen = () => setFullscreenImage(null);
  return (
    <div className="dashboard-container">
      <div
        className={`overlay ${isOpen ? "active" : ""}`}
        onClick={() => setIsOpen(false)}
      />
      <div className={`sidebar ${isOpen ? "open" : ""}`}>
        <div className="top-div">
          <h2>Free Systems</h2>
        </div>
        {unclaimedSystems.map((sys) => (
          <UnclaimedSystem
            key={sys.id}
            moveSystemToDashboard={moveSystemToDashboard}
            {...sys}
          />
        ))}
        {unclaimedSystems.length === 0 && (
          <main
            style={{
              textAlign: "center",
              left: "0",
              position: "absolute",
              top: "20%",
              width: "100%",
            }}
          >
            <Image src="noSystems.png" className="noFreeSystemsPic " rounded />
            <h1 style={{ fontSize: "2rem", fontWeight: "200" }}>
              No Free Systems
            </h1>
          </main>
        )}
      </div>
      <div className="systems-grid">
        {claimedSystems.map((sys) => (
          <ClaimedSystem
            key={sys.id}
            id={sys.id}
            system_name={sys.system_name}
            patient_name={sys.patient_name}
            status={sys.status}
            claimedby={sys.claimedby}
            battery_lvl={sys.battery_lvl}
            charge_status={sys.charge_status}
            signalStrength={80}
            unclaimSystem={unclaimSystem}
            runCheck={runCheck}
            moveSystemToDashboard={moveSystemToDashboard}
          />
        ))}
      </div>

      {claimedSystems.length === 0 && !selectedUser && (
        <main
          className={`content ${isOpen ? "dimmed" : ""}`}
          style={{
            textAlign: "center",
            left: "0",
            position: "absolute",
            width: "100%",
          }}
        >
          <img src="noSystems.png" style={{ width: "25%" }}></img>
          <h1 style={{ fontSize: "2rem" }}>No Systems Selected</h1>
          <p>Select systems from the top left menu</p>
        </main>
      )}

      {claimedSystems.length === 0 && selectedUser && (
        <main
          className={`content ${isOpen ? "dimmed" : ""}`}
          style={{
            textAlign: "center",
            left: "0",
            position: "absolute",
            width: "100%",
          }}
        >
          <h1>{selectedUserName} has no systems</h1>
        </main>
      )}
      <>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          className="custom-modal"
          overlayClassName="custom-overlay"
        >
          <div className="modal-content">
            {modalSystems.map((system) => (
              <div key={system.id} className="system-section">
                <div className="system-title">
                  <div>{system.system_name}</div>
                  <div className="system-actions">
                    <Button
                      variant="success"
                      onClick={() => handleApprove(system)}
                      className="me-2"
                    >
                      Accept
                    </Button>
                    <Button variant="danger" onClick={() => handleDeny(system)}>
                      Reject
                    </Button>
                  </div>
                </div>
                <div className="system-content">
                  <textarea
                    placeholder="Add a note (optional)"
                    value={notes[system.id] || ""}
                    onChange={(e) =>
                      handleNoteChange(system.id, e.target.value)
                    }
                    style={{ width: "50%", marginRight: "10px" }}
                    className="note-input"
                  />
                  <img
                    src={system.screenshot}
                    alt={`${system.system_name} Screenshot`}
                    className="screenshot"
                    onClick={() => handleImageClick(system.screenshot!)}
                  />
                </div>
              </div>
            ))}
          </div>
        </Modal>

        {fullscreenImage && (
          <div className="fullscreen-modal" onClick={closeFullscreen}>
            <img
              src={fullscreenImage}
              alt="Fullscreen Screenshot"
              className="fullscreen-image"
            />
          </div>
        )}
      </>
    </div>
  );
};

export default Dashboard;
