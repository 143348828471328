import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

interface UnclaimedSystem {
  id: number;
  system_name: string;
  patient_name: string;
  status: string;
  battery_lvl: number;
  charge_status: boolean;
  claimedby: string | null;
  moveSystemToDashboard: (id: number) => void;
}

const UnclaimedSystem: React.FC<UnclaimedSystem> = ({
  id,
  system_name,
  patient_name,
  status,
  moveSystemToDashboard,
}) => {
  const isInvalidHeadbox = status === "invalidHeadbox";
  return (
    <Card
      style={{
        height: "20%",
        minHeight: "185px",
        width: "92%",
        margin: "auto",
        marginTop: "10px",
      }}
      bg={isInvalidHeadbox ? "warning" : status === "Offline" ? "danger" : ""}
    >
      <Card.Header as="h5">{system_name}</Card.Header>
      <Card.Body>
        <Card.Title>{patient_name}</Card.Title>
        <Card.Text>{status === "Offline" ? "Offline" : "Online"}</Card.Text>
        <Button
          variant="primary"
          onClick={() => {
            moveSystemToDashboard(id);
          }}
        >
          Claim
        </Button>
      </Card.Body>
    </Card>
  );
};
export default UnclaimedSystem;
