import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner"; // Import Spinner for loading effect
import Toast from "react-bootstrap/Toast"; // Import Toast for notifications
import getSubdomain from "../services/utils/utiltyFunctions";

interface AddSystemFormProps {
  customerId: string;
  handleSuccess: (system: any) => void;
  handleError: (error: any) => void;
}

const AddSystemForm: React.FC<AddSystemFormProps> = ({
  customerId,
  handleSuccess,
  handleError,
}) => {
  const subdomain = getSubdomain();
  const [systemName, setSystemName] = useState<string>("");
  const [licenseType, setLicenseType] = useState<string>(
    "intermittentMonitoring"
  );
  const [cardLastFour, setCardLastFour] = useState<string | null>(null); // Last 4 digits of the card
  const [licensePrice, setLicensePrice] = useState<number>(0); // License price
  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const [submitting, setSubmitting] = useState<boolean>(false); // Submitting state
  const [toastMessage, setToastMessage] = useState<string | null>(null); // Toast message state
  const [showToast, setShowToast] = useState<boolean>(false); // Show/hide toast state

  // Fetch payment method and license prices when the component mounts
  useEffect(() => {
    const fetchPaymentDetailsAndPrice = async () => {
      try {
        // Fetch payment method
        const paymentMethodResponse = await fetch(
          `/api/v1/getPaymentMethod/${subdomain}`
        );
        const paymentMethodData = await paymentMethodResponse.json();
        if (paymentMethodData && paymentMethodData.card) {
          setCardLastFour(paymentMethodData.card.last4);
        }

        // Fetch license prices
        const licensePriceResponse = await fetch(
          `/api/v1/getLicensePrice/${licenseType}`
        );
        const licensePriceData = await licensePriceResponse.json();
        if (licensePriceData && licensePriceData.price) {
          setLicensePrice(licensePriceData.price);
        }
      } catch (error) {
        console.error(
          "Error fetching payment method and license price:",
          error
        );
      } finally {
        setLoading(false);
      }
    };

    fetchPaymentDetailsAndPrice();
  }, [customerId, licenseType, subdomain]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitting(true); // Start submitting

    try {
      const response = await fetch("/api/v1/createSystem", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          systemName,
          licenseType,
          subdomain,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to create system.");
      }

      const data = await response.json();

      // Show toast message if it exists
      if (data.message) {
        setToastMessage(data.message);
        setShowToast(true);
      }

      // Handle success with system data
      handleSuccess(data.system);
    } catch (error) {
      handleError(error);
    } finally {
      setSubmitting(false); // Stop submitting
    }
  };

  return (
    <>
      {/* Toast Notification */}
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
        style={{
          position: "fixed",
          bottom: 20,
          right: 20,
          zIndex: 1050,
        }}
      >
        <Toast.Header>
          <strong className="me-auto">Notification</strong>
        </Toast.Header>
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>

      <Form onSubmit={handleSubmit}>
        {loading ? (
          <div>Loading payment details...</div>
        ) : (
          <>
            <Form.Group controlId="formSystemName" className="mb-3">
              <Form.Label>System Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter system name"
                value={systemName}
                onChange={(e) => setSystemName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formLicenseType" className="mb-3">
              <Form.Label>License Type</Form.Label>
              <Form.Select
                value={licenseType}
                onChange={(e) => {
                  setLicenseType(e.target.value);
                  setLoading(true); // Set loading while fetching new price
                }}
              >
                <option value="intermittentMonitoring">
                  Intermittent Monitoring
                </option>
                <option disabled value="liveMonitoring">
                  Live Monitoring
                </option>
              </Form.Select>
            </Form.Group>

            {cardLastFour && (
              <Form.Group controlId="formCardLastFour" className="mb-3">
                <Form.Label>Payment Method (Card Last 4)</Form.Label>
                <Form.Control
                  type="text"
                  value={`**** **** **** ${cardLastFour}`}
                  disabled
                />
              </Form.Group>
            )}

            <div className="mb-3">
              <strong>License Price:</strong> ${licensePrice.toFixed(2)} per
              month
            </div>

            <Button
              variant="primary"
              type="submit"
              className="w-100"
              disabled={submitting}
            >
              {submitting ? (
                <>
                  <Spinner animation="border" size="sm" /> Processing...
                </>
              ) : (
                "Add System and Subscribe"
              )}
            </Button>
          </>
        )}
      </Form>
    </>
  );
};

export default AddSystemForm;
