import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import "./Navbar.css";
import { Dropdown, DropdownButton, Button, ButtonGroup } from "react-bootstrap";
import { CircleUser, Menu, MoreVertical } from "lucide-react"; // Import MoreVertical icon
import { useAuth } from "../Contexts/AuthContext";
import { useOnlineUsers } from "../Contexts/OnlineUsersContext";

interface NavbarProps {
  toggleMenu: () => void;
  selectedUserName: string;
  onUserSelect: (userId: string | null, username: string) => void;
}

const NavBar: React.FC<NavbarProps> = ({
  toggleMenu,
  selectedUserName,
  onUserSelect,
}) => {
  const auth = useAuth();
  const { onlineUsers } = useOnlineUsers();

  return (
    <Navbar
      style={{
        backgroundColor: "rgb(13 110 253)",
        height: "3.5rem",
      }}
    >
      <Container className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          {auth.isAuthenticated && (
            <Button
              variant="outline"
              onClick={toggleMenu}
              style={{ color: "white" }}
            >
              <Menu className="h-4 w-4" />
            </Button>
          )}
        </div>

        <Navbar.Brand className="mx-auto navBrand">EpiCareAlert</Navbar.Brand>

        {auth.isAuthenticated && (
          <div className="d-flex align-items-center">
            <DropdownButton
              as={ButtonGroup}
              title={selectedUserName}
              className="me-2"
              style={{
                backgroundColor: "rgb(13 110 253)", // Blue background color
                fontSize: "20px",
                borderColor: "rgb(13 110 253)", // Matching border color
              }}
            >
              <Dropdown.Header>Online Users</Dropdown.Header>
              {onlineUsers.length > 0 ? (
                onlineUsers.map((user) => (
                  <Dropdown.Item
                    key={user.id}
                    onClick={() =>
                      onUserSelect(
                        user.id === auth.user?.sub ? null : user.id,
                        user.id === auth.user?.sub ? "Online Users" : user.name
                      )
                    }
                    // White text color for items
                  >
                    {user.id === auth.user?.sub ? "Me" : user.name}
                  </Dropdown.Item>
                ))
              ) : (
                <Dropdown.Item>No users online</Dropdown.Item>
              )}
            </DropdownButton>
          </div>
        )}
      </Container>
    </Navbar>
  );
};

export default NavBar;
