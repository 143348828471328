import React, { useState, useEffect } from "react";
import UserCard from "../components/UserCard";
import AdminPanelSystems from "../components/AdminPanelSystem";
import UserFormModal from "../components/UserFormModal";
import SystemFormModal from "../components/SystemFormModal";
import "./AdminPanel.css";

import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import PaymentForm from "./AddPaymentForm"; // Import your payment form
import AddSystemForm from "./AddSystemForm"; // Import your payment form
import CancelSubscriptionModal from "../components/CancelSubscriptionModal";
// Stripe imports
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Modal, Button } from "react-bootstrap";
import { getUsersInOrg } from "../services/api/getUsersInOrg";
import { createNewUser } from "../services/api/createNewUser";
import { getOrgLicenses } from "../services/api/getOrgLicenses";
import { getOrgSystems } from "../services/api/getOrgSystems";
import { getOrgCustomerId } from "../services/api/getOrgCustomerId";
import { createNewSystem } from "../services/api/createNewSystem";

// Load Stripe with your publishable key
const stripePromise = loadStripe(
  "pk_test_51Q0sbMJSzKKfLMh9mt2mqmCTuv9ZRHiu8kerKLzcyegCq7JroJsCTC0DnzlmFrBCMAkiM0NLvHWJmUaq6RxK9YBG002PlmHuk2"
);

interface User {
  sub: string;
  username: string;
  fullname: string;
  phonenumber: string;
  role: string;
}

interface System {
  id: number;
  system_name: string;
  status: string;
  guid: string;
}

const AdminPanel = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [systems, setSystems] = useState<System[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [showAddSystemModal, setShowAddSystemModal] = useState(false);
  const [showUserModal, setShowUserModal] = useState<boolean>(false);
  const [selectedSystemForCancel, setSelectedSystemForCancel] =
    useState<System | null>(null); // Selected system for cancellation

  const [showToast, setToast] = useState<boolean>(false); // For toast notifications
  const [licenseCounts, setLicenseCounts] = useState(0);

  const [showPaymentModal, setShowPaymentModal] = useState(false); // Payment modal state
  const [customerId, setCustomerId] = useState<string | null>(null); // Store customer ID

  // Check if there's an existing customer ID for the organization
  // Fetch customerId to check if payment details exist
  useEffect(() => {
    const checkCustomerId = async () => {
      try {
        const customer = await getOrgCustomerId();
        setCustomerId(customer?.customerId || null);
      } catch (error) {
        console.error("Error fetching customer ID:", error);
        setError("Failed to load payment information.");
      }
    };

    checkCustomerId();
  }, []);

  const handleAddSystemClick = () => {
    console.log(customerId);
    if (customerId) {
      // If customerId exists, show the Add System form/modal
      setShowAddSystemModal(true);
    } else {
      // If no customerId, show the Payment Form
      setShowPaymentModal(true);
    }
  };

  const handlePaymentSuccess = (data: any) => {
    setShowPaymentModal(false);
    // Handle system creation success
    console.log("Payment added successfully:", data);
    // Add the newly created payment
    setCustomerId(data.customerId);
  };

  const handlePaymentFailure = (error: any) => {
    // Handle payment failure
    console.error("Add Payment failed:", error);
  };

  useEffect(() => {
    // Fetch users, systems, and licenses...
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);

        // Fetch users
        const users = await getUsersInOrg();
        if (users) {
          const sortedUsers = users.sort((a: User, b: User) => {
            if (a.role === "admin" && b.role !== "admin") return -1;
            if (a.role !== "admin" && b.role === "admin") return 1;
            return 0;
          });
          setUsers(sortedUsers);
        } else {
          setError("No users found");
        }

        // Fetch licenses
        const licenses = await getOrgLicenses();

        if (licenses && licenses.error) {
          // setError(licenses.error);
          // setToast(true);
        } else if (Array.isArray(licenses)) {
          setLicenseCounts(licenses.length);
        } else {
          setError("Failed to retrieve licenses");
          setToast(true);
        }

        // Fetch systems from the backend
        const fetchedSystems = await getOrgSystems(); // Fetch systems from the backend
        console.log(fetchedSystems);
        setSystems(fetchedSystems);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to load data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleAddUser = () => {
    setShowUserModal(true);
  };

  const handleSaveUser = async (newUser: {
    fullname: string;
    username: string;
    phonenumber: string;
    password: string;
    role: string;
  }) => {
    try {
      const response = await createNewUser(
        newUser.fullname,
        newUser.username,
        newUser.phonenumber,
        newUser.password,
        newUser.role
      );
      if (response?.message) {
        // Handle the 'User account already exists' error
        setError(response.message);
        setToast(true); // Show the toast
        return;
      } else {
        setUsers([...users, response]);
        setShowUserModal(false);
      }
    } catch (error: any) {
      console.error("Error creating user:", error);
      setError("Failed to create user");
      setToast(true); // Show the toast
      setShowUserModal(true);
    }
  };

  const handleEditUser = (id: string) => {
    console.log(`Edit user with ID: ${id}`);
  };

  const handleDeleteUser = (id: string) => {
    console.log(`Delete user with ID: ${id}`);
  };

  const handleDeleteSystem = (systemId: number) => {
    console.log(`Delete system with ID: ${systemId}`);
  };
  const handleUnsubscribe = (system: System) => {
    setSelectedSystemForCancel(system); // Set the selected system for cancellation
  };

  const confirmUnsubscribe = async () => {
    if (!selectedSystemForCancel) return;
    try {
      const response = await fetch("/api/v1/cancelSubscription", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ guid: selectedSystemForCancel.guid }),
      });

      if (response.ok) {
        const data = await response.json();
        setSystems((prevSystems) =>
          prevSystems.map((s) =>
            s.guid === selectedSystemForCancel.guid
              ? { ...s, status: "unsubscribed" }
              : s
          )
        );
      } else {
        const errorData = await response.json();
        setError(errorData.message);
        setToast(true);
      }
    } catch (error) {
      setError("An error occurred while canceling the subscription");
      setToast(true);
    } finally {
      setSelectedSystemForCancel(null); // Close the modal
    }
  };

  const handleCloseCancelModal = () => setSelectedSystemForCancel(null);

  if (loading) return <div>Loading users...</div>;

  return (
    <div className="admin-panel">
      {/* Toast Notification */}
      <ToastContainer
        className="p-3"
        position={"top-end"}
        style={{ zIndex: 1060 }}
      >
        <Toast
          onClose={() => setToast(false)}
          show={showToast}
          delay={3000}
          bg={"danger"}
          autohide
        >
          <Toast.Header className="bg-dark text-white">
            <strong className="me-auto">Error</strong>
          </Toast.Header>
          <Toast.Body className={"text-white"}>{error}</Toast.Body>
        </Toast>
      </ToastContainer>

      {/* Users Section */}
      <div className="section-header-container">
        <h2 className="section-header">Users</h2>
        <button className="add-user-button" onClick={handleAddUser}>
          Add User
        </button>
      </div>
      <div className="user-grid">
        {users.length > 0 &&
          users.map((user) => (
            <UserCard
              key={user.sub}
              id={user.sub}
              fullName={user.fullname}
              username={user.username}
              phoneNumber={user.phonenumber}
              role={user.role}
              onEdit={handleEditUser}
              onDelete={handleDeleteUser}
            />
          ))}
      </div>

      {/* Systems Section */}
      <div className="section-header-container">
        <h2 className="section-header">Systems</h2>
        <button
          className="add-system-button"
          onClick={handleAddSystemClick} // Open payment modal
        >
          {customerId ? "Add System" : "Add Payment Information"}
        </button>
      </div>
      <div className="sub-header">
        <h5>Total Licenses - ({licenseCounts})</h5>
      </div>
      <AdminPanelSystems
        systems={systems.map((system) => ({
          id: system.id,
          system_name: system.system_name,
          status: system.status,
          guid: system.guid, // Pass the guid here
          onUnsubscribe: handleUnsubscribe, // Pass the handleUnsubscribe function
          onDelete: handleDeleteSystem,
        }))}
      />

      {/* User Form Modal */}
      <UserFormModal
        show={showUserModal}
        handleClose={() => setShowUserModal(false)}
        handleSave={handleSaveUser}
      />

      {/* Payment Form Modal */}
      <Elements stripe={stripePromise}>
        <PaymentForm
          show={showPaymentModal}
          handleClose={() => setShowPaymentModal(false)}
          handlePaymentSuccess={handlePaymentSuccess}
          handlePaymentFailure={handlePaymentFailure}
        />
      </Elements>

      {/* Add System Form Modal */}
      <Modal
        show={showAddSystemModal}
        onHide={() => setShowAddSystemModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add System</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddSystemForm
            customerId={customerId!} // Pass the customerId to the AddSystemForm
            handleSuccess={(system: System) => {
              setSystems([...systems, system]);
              setShowAddSystemModal(false);
            }}
            handleError={(error: any) => {
              console.error("Error adding system:", error);
            }}
          />
        </Modal.Body>
      </Modal>
      {/* Cancel Subscription Modal */}
      {selectedSystemForCancel && (
        <CancelSubscriptionModal
          show={!!selectedSystemForCancel}
          handleClose={handleCloseCancelModal}
          handleConfirm={confirmUnsubscribe}
          systemName={selectedSystemForCancel.system_name}
        />
      )}
    </div>
  );
};

export default AdminPanel;
