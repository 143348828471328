import getSubdomain from "../utils/utiltyFunctions";
export async function getOrgLicenses() {
  const subdomain = getSubdomain();
  try {
    // Fetch the number of available licenses from the backend
    const response = await fetch("/api/v1/getOrgLicenses", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        subdomain,
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to fetch organization licenses");
    }

    const data = await response.json();
    console.log(data);
    const availableLicenses = data.licenses; // Adjust according to the backend response structure
    if (availableLicenses.length <= 0) {
      // No licenses available
      return { error: "No available licenses to add a new system." };
    }
    return availableLicenses;
  } catch (error: any) {
    console.error("Error checking licenses:", error);
    return { error: error.message || "Failed to check licenses" };
  }
}
