import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";
import { useLocation } from "react-router-dom";
import {
  connectSocket,
  getSocket,
  disconnectSocket,
} from "../../services/socket";

// Define the shape of your user object
interface User {
  sub: string;
  username: string;
  fullname: string;
  phonenumber: string;
  organizationid: number;
  created_at: string;
  role: string;
}

// Extend the AuthContextType to include the user object
interface AuthContextType {
  isAuthenticated: boolean;
  isLoading: boolean;
  user: User | null;
  login: (userData: User) => void;
  logout: () => void;
}

// Create the AuthContext with a default value
const AuthContext = createContext<AuthContextType>(null!);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const BASE_URL = "http://localhost:8080";
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null); // State to store the user object// Update the login function to accept and store the entire user object

  const login = (userData: User) => {
    setUser(userData);
    setIsAuthenticated(true);
    localStorage.setItem("user", JSON.stringify(userData));

    connectSocket(userData.sub);
  };

  // Update the logout function to clear the user object
  const logout = async () => {
    try {
      const response = await fetch("/api/v1/logout", {
        method: "GET",
        credentials: "include",
      });
      if (response.ok) {
        setIsAuthenticated(false);
        setUser(null); // Clear user data on logout
        localStorage.removeItem("user");

        // Disconnect the socket on logout
        disconnectSocket();
      } else {
        console.error("Logout failed");
      }
    } catch (error) {
      console.error("An error occurred during logout:", error);
    }
  };

  // Verify the session and set the user data if authenticated
  const verifySession = async () => {
    setIsLoading(true);
    try {
      const response = await fetch("/api/v1/verify-session", {
        credentials: "include",
      });
      console.log(response);
      const data = await response.json();

      if (data.isAuthenticated) {
        setIsAuthenticated(true);
        setUser(data.user); // Set the full user object from session data
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  // Helper function to check for a specific cookie
  const checkCookie = (name: string) => {
    return document.cookie
      .split(";")
      .some((item) => item.trim().startsWith(name + "="));
  };

  // Use useEffect to check the session on initial load
  useEffect(() => {
    if (checkCookie("token")) {
      verifySession();
    } else {
      setIsAuthenticated(false);
      setIsLoading(false);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, isAuthenticated, isLoading, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the auth context
export const useAuth = () => useContext(AuthContext);
