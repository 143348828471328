import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import ProgressBar from "react-bootstrap/ProgressBar";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FaTimes } from "react-icons/fa";
import {
  BiWifi,
  BiWifi2,
  BiWifi1,
  BiWifiOff,
  BiSolidBatteryCharging,
} from "react-icons/bi";
import { ImSpinner2 } from "react-icons/im"; // Import the spinner icon
import "./ClaimedSystem.css";

interface ClaimedSystem {
  id: number;
  system_name: string;
  patient_name: string;
  status: string;
  battery_lvl: number;
  charge_status: boolean;
  signalStrength: number;
  screenshot?: any;
  claimedby: string | null;
  moveSystemToDashboard: (id: number) => void;
  unclaimSystem: (id: number) => void;
  runCheck: (id: number) => Promise<void>;
}

const ClaimedSystem: React.FC<ClaimedSystem> = ({
  id,
  system_name,
  patient_name,
  status,
  battery_lvl,
  charge_status,
  signalStrength,
  unclaimSystem,
  runCheck,
  moveSystemToDashboard,
}) => {
  const [isRunningCheck, setIsRunningCheck] = useState(false);

  const handleRunCheck = () => {
    setIsRunningCheck(true);
    setTimeout(() => {
      setIsRunningCheck(false);
    }, 3000);
  };

  const getProgressBarVariant = () => {
    if (battery_lvl > 75) return "success";
    if (battery_lvl > 50) return "warning";
    if (battery_lvl > 25) return "danger";
    return "danger";
  };

  const getWifiIcon = () => {
    if (signalStrength > 75) return <BiWifi className="text-success" />;
    if (signalStrength > 50) return <BiWifi2 className="text-warning" />;
    if (signalStrength > 25) return <BiWifi1 className="text-danger" />;
    return <BiWifiOff className="text-danger" />;
  };

  const renderTooltip = (props: any) => (
    <Tooltip id="battery-tooltip" {...props}>
      {battery_lvl}% {charge_status && "(Charging)"}
    </Tooltip>
  );

  const isInvalidHeadbox = status === "invalidHeadbox";

  return (
    <Card
      bg={isInvalidHeadbox ? "warning" : status === "Offline" ? "danger" : ""}
      className="mb-3"
    >
      <Card.Header
        as="h5"
        className="d-flex justify-content-between align-items-center"
      >
        <div className="d-flex align-items-center">
          {system_name}
          <div className="ms-2">{getWifiIcon()}</div>
        </div>
        <Button
          variant="light"
          size="sm"
          onClick={() => unclaimSystem(id)}
          style={{ padding: "0.25rem 0.5rem" }}
        >
          <FaTimes />
        </Button>
      </Card.Header>

      <Card.Body>
        <Card.Title>{patient_name}</Card.Title>
        <Button
          variant={
            isInvalidHeadbox
              ? "secondary"
              : status === "Offline"
              ? "secondary"
              : "primary"
          }
          onClick={() => runCheck(id)}
          disabled={
            isInvalidHeadbox ||
            status === "Offline" ||
            status === "RUN_CHECK" ||
            status === "logging_in" ||
            status === "taking_ss" ||
            status === "awaiting_approval"
          }
        >
          {isInvalidHeadbox ? (
            "Invalid Headbox"
          ) : status === "Offline" ? (
            "Offline"
          ) : status === "RUN_CHECK" ? (
            <>
              <ImSpinner2 className="spinner-icon" /> Running...
            </>
          ) : status === "logging_in" ? (
            <>
              <ImSpinner2 className="spinner-icon" /> Logging In...
            </>
          ) : status === "taking_ss" ? (
            <>
              <ImSpinner2 className="spinner-icon" /> Taking Screenshot...
            </>
          ) : status === "awaiting_approval" ? (
            "Awaiting Approval"
          ) : (
            "Run Check"
          )}
        </Button>

        <div className="d-flex align-items-center mt-3">
          <OverlayTrigger placement="top" overlay={renderTooltip}>
            <div
              style={{ position: "relative", width: "100%", height: "20px" }}
            >
              <ProgressBar
                now={battery_lvl}
                variant={getProgressBarVariant()}
                style={{
                  height: "100%",
                  borderRadius: "5px",
                  position: "relative",
                }}
              />
              {charge_status && (
                <BiSolidBatteryCharging
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "white",
                    fontSize: "1rem",
                  }}
                />
              )}
            </div>
          </OverlayTrigger>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ClaimedSystem;
