import getSubdomain from "../utils/utiltyFunctions";

export async function createNewUser(
  fullname: string,
  username: string,
  phonenumber: string,
  password: string,
  role: string
) {
  const subdomain = getSubdomain();
  try {
    const response = await fetch("/api/v1/createNewUser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fullname,
        username,
        phonenumber,
        password,
        role,
        subdomain,
      }),
    });
    if (response.status === 409) {
      const errorMessage = await response.json();
      return errorMessage;
    }
    const user = await response.json();
    console.log(user);
    if (user) return user;
    else return null;
  } catch (error) {
    console.error("Error finding users in org:", error);
    return error;
  }
}
