import React, { useState } from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";

interface UserFormModalProps {
  show: boolean;
  handleClose: () => void;
  handleSave: (user: {
    fullname: string;
    username: string;
    phonenumber: string;
    password: string;
    role: string;
  }) => void;
}

const UserFormModal: React.FC<UserFormModalProps> = ({
  show,
  handleClose,
  handleSave,
}) => {
  const [fullname, setFullName] = useState("");
  const [username, setUsername] = useState("");
  const [phonenumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("user");

  const [errors, setErrors] = useState<string[]>([]); // For error messages

  const validateForm = () => {
    const newErrors = [];

    // Full Name Validation
    if (!fullname.trim()) {
      newErrors.push("Full name is required.");
    }

    // Email Validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!username.trim()) {
      newErrors.push("Email address is required.");
    } else if (!emailRegex.test(username)) {
      newErrors.push("Please enter a valid email address.");
    }

    // Password Validation
    if (!password) {
      newErrors.push("Password is required.");
    } else if (password.length < 8) {
      newErrors.push("Password must be at least 8 characters long.");
    }

    // Phone Number Validation
    const phoneRegex = /^\+[1-9]\d{1,14}$/; // E.164 format
    if (!phonenumber.trim()) {
      newErrors.push("Phone number is required.");
    } else if (!phoneRegex.test(phonenumber)) {
      newErrors.push("Please enter a valid phone number (e.g., +1234567890).");
    }

    setErrors(newErrors);
    return newErrors.length === 0; // Return true if no errors
  };

  const onSave = () => {
    if (validateForm()) {
      // If form is valid, proceed to save
      handleSave({ fullname, username, phonenumber, role, password });
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add New User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errors.length > 0 && (
          <Alert variant="danger">
            <ul>
              {errors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          </Alert>
        )}
        <Form>
          <Form.Group controlId="formFullName">
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter full name"
              value={fullname}
              onChange={(e) => setFullName(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formUsername" className="mt-3">
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter email address"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formPassword" className="mt-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formPhoneNumber" className="mt-3">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter phone number"
              value={phonenumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formRole" className="mt-3">
            <Form.Label>Role</Form.Label>
            <Form.Control
              as="select"
              value={role}
              onChange={(e) => setRole(e.target.value)}
            >
              <option value="user">User</option>
              <option value="admin">Admin</option>
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={onSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserFormModal;
