"use client";
import { useForm } from "react-hook-form";
import Spinner from "react-bootstrap/Spinner";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import { loginHandler } from "../services/api/authHandler";
import { orgChecker } from "../services/api/orgChecker";
import { checkUserInOrg } from "../services/api/checkUserInOrg";
import React, { useState, useEffect } from "react";
import { useAuth } from "../components/Contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

interface OrgResponse {
  message: string;
  friendlyname: string;
}

export default function LoginForm() {
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const auth = useAuth();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [isOrgLoading, setOrgLoading] = useState(true);
  const [orgFound, setOrgFound] = useState<OrgResponse | null>(null);

  const [showToast, setToast] = useState(false);

  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate("/dashboard");
    }
  }, [auth.isAuthenticated, navigate]);

  useEffect(() => {
    const checkOrg = async () => {
      setOrgLoading(true);
      try {
        const exists = await orgChecker();
        setOrgFound(exists);
      } catch (error) {
        setOrgFound(null);
      } finally {
        setOrgLoading(false);
      }
    };

    checkOrg();
  }, []);

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true); // Start the spinner
    if (emailRegex.test(email)) {
      try {
        const login = await loginHandler(email, password);
        if (login?.message !== "Success") throw new Error(login.message);
        if (!login?.sub) throw new Error("User Account not found");
        const userExists = await checkUserInOrg(login.sub);
        if (!userExists) {
          throw new Error("User does not exist in the organization database.");
        }
        console.log(userExists);
        auth.login(userExists.user);
      } catch (error: any) {
        setErrorMsg(error.message || "An unknown error occurred");
        setToast(true);
        console.error("Login failed", error);
      }
    } else {
      setErrorMsg("Email Address is invalid");
      setToast(true);
    }

    setLoading(false); // Stop the spinner after login operation
  };

  if (auth.isLoading && auth.isAuthenticated) {
    return (
      <Spinner
        animation="border"
        role="status"
        style={{ marginLeft: "48.5%", marginTop: "20px" }}
      >
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }

  if (isOrgLoading) {
    return (
      <Spinner
        animation="border"
        role="status"
        style={{ marginLeft: "48.5%", marginTop: "20px" }}
      >
        <span className="visually-hidden">Checking organization...</span>
      </Spinner>
    );
  }

  if (!orgFound) {
    return (
      <div style={{ width: "50%", margin: "auto", marginTop: "10px" }}>
        <Card>
          <Card.Header>Uh Oh</Card.Header>
          <Card.Body>
            <Card.Title>Organization not found</Card.Title>
            <Card.Text>
              The organization url you are using does not exist.
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    );
  }

  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ marginTop: "10px" }}
    >
      <Row>
        <Col>
          <ToastContainer
            className="p-3"
            position={"top-end"}
            style={{ zIndex: 1 }}
          >
            <Toast
              onClose={() => setToast(false)}
              show={showToast}
              delay={3000}
              bg={"danger"}
              autohide
            >
              <Toast.Header className="bg-dark text-white">
                <strong className="me-auto">Error</strong>
              </Toast.Header>
              <Toast.Body className={"text-white"}>{errorMsg}</Toast.Body>
            </Toast>
          </ToastContainer>

          <Card style={{ width: "25rem", padding: "20px" }}>
            <Card.Body>
              <Card.Title className="text-center">
                {orgFound?.friendlyname}
              </Card.Title>
              <Form onSubmit={handleLogin}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={isLoading}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    disabled={isLoading}
                  />
                </Form.Group>

                <Button
                  variant="primary"
                  type="submit"
                  className="w-100"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Login"
                  )}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

const emailRegex = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
