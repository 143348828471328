import React, { createContext, useContext, useState, ReactNode } from "react";

interface User {
  id: string;
  name: string;
}

interface OnlineUsersContextType {
  onlineUsers: User[];
  setOnlineUsers: React.Dispatch<React.SetStateAction<User[]>>;
}

const OnlineUsersContext = createContext<OnlineUsersContextType | undefined>(
  undefined
);

export const OnlineUsersProvider = ({ children }: { children: ReactNode }) => {
  const [onlineUsers, setOnlineUsers] = useState<User[]>([]);

  return (
    <OnlineUsersContext.Provider value={{ onlineUsers, setOnlineUsers }}>
      {children}
    </OnlineUsersContext.Provider>
  );
};

export const useOnlineUsers = () => {
  const context = useContext(OnlineUsersContext);
  if (!context) {
    throw new Error(
      "useOnlineUsers must be used within an OnlineUsersProvider"
    );
  }
  return context;
};
