// App.tsx
import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./Pages/Login";
import AdminPanel from "./Pages/AdminPanel";
import Dashboard from "./Pages/Dashboard";
import NavBar from "./components/Navbar/Navbar";
import { useAuth } from "./components/Contexts/AuthContext";
import { OnlineUsersProvider } from "./components/Contexts/OnlineUsersContext";
import { SystemsProvider } from "./components/Contexts/SystemsContext"; // Import SystemsProvider
import ProtectedRoute from "./components/ProtectedRoute";
import { useSocket } from "./components/Contexts/SocketContext"; // Import the SocketProvider
import { ToastContainer } from "react-toastify"; // Import ToastContainer
import "react-toastify/dist/ReactToastify.css"; // Import CSS for toast notifications

interface System {
  id: number;
  system_name: string;
  patient_name: string;
  status: string;
  battery_lvl: number;
  charge_status: boolean;
  claimedby: string | null;
}

const App = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [selectedUserName, setSelectedUserName] =
    useState<string>("Online Users");
  const auth = useAuth();
  const { socket } = useSocket(); // Get the socket instance here
  const handleUserSelect = (userId: string | null, username: string) => {
    setSelectedUser(userId);
    setSelectedUserName(username);
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const moveSystemToDashboard = (id: number) => {
    if (socket) {
      socket.emit("claimSystem", id);
    }
  };

  const unclaimSystem = (id: number) => {
    if (socket) {
      socket.emit("unclaimSystem", id);
    }
  };

  return (
    <>
      <SystemsProvider>
        {" "}
        {/* Wrap the app with SystemsProvider */}
        <ToastContainer position="top-right" autoClose={3000} />
        <OnlineUsersProvider>
          {" "}
          {/* Wrap the app in the SocketProvider */}
          <NavBar
            toggleMenu={toggleMenu}
            selectedUserName={selectedUserName}
            onUserSelect={handleUserSelect}
          />
          <Routes>
            <Route path="/" element={<Login />} />

            {/* Only authenticated users can access these routes */}
            <Route element={<ProtectedRoute />}>
              <Route
                path="/dashboard"
                element={
                  <Dashboard
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    unclaimSystem={unclaimSystem}
                    moveSystemToDashboard={moveSystemToDashboard}
                    selectedUser={selectedUser}
                    selectedUserName={selectedUserName}
                  />
                }
              />

              {/* Conditionally show the admin panel based on user role */}
              {auth?.user?.role === "admin" && (
                <Route path="/admin" element={<AdminPanel />} />
              )}
            </Route>

            {/* Catch-all route */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </OnlineUsersProvider>
      </SystemsProvider>
    </>
  );
};

export default App;
