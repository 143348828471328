import getSubdomain from "../utils/utiltyFunctions";

export async function getUsersInOrg() {
  const subdomain = getSubdomain();
  try {
    const response = await fetch("/api/v1/getUsersInOrg", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ subdomain }),
    });

    const data = await response.json();

    if (data?.user) return data.user;
    else return null;
  } catch (error) {
    console.error("Error finding users in org:", error);
    return error;
  }
}
